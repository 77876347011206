import Skeleton from "react-loading-skeleton";
import { ContactUs, GalleryPictureList } from "../Components";
import heroImage from "../Public/Gallery/Thumbnails/our_work_main_thumbnail.webp";
import { useImagePreloader } from "../Components/Hooks";
import { config } from "../config";
import { Helmet } from "react-helmet-async";

function Gallery() {
  const { isPreloaded } = useImagePreloader([heroImage]);

  return (
    <div className="page">
      <Helmet>
        <link rel="canonical" href={config.baseUrl + config.outWorkPath} />
      </Helmet>
      <header className="page-section">
        <div className="image-container page-right-section">
          <div className="image-wire-right">
            {isPreloaded ? (
              <img
                className="eirfrost-image"
                src={heroImage}
                alt="Compressor room"
              />
            ) : (
              <Skeleton className="eirfrost-image-skeleton" />
            )}
          </div>
        </div>
        <div className="page-left-section">
          <h1>Our work</h1>
          <p style={{ marginBottom: 0 }}>
            Welcome to our gallery showcasing the innovative CO2 refrigeration
            systems installed by Eirfrost. As a pioneer in sustainable cooling
            solutions, Eirfrost has been at the forefront of revolutionizing the
            refrigeration industry.
          </p>
        </div>
      </header>
      <GalleryPictureList />
      <ContactUs />
    </div>
  );
}

export default Gallery;
