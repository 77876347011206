import { Helmet } from "react-helmet-async";
import { config } from "../config";

function Privacy() {
  return (
    <div className="page">
      <Helmet>
        <link rel="canonical" href={config.baseUrl + config.privacyPath} />
      </Helmet>
      <header className="page-section" style={{ minHeight: "0" }}>
        <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
      </header>
      <div className="page-section" style={{ display: "block" }}>
        <p style={{ textAlign: "left" }}>
          eirfrost.ie (the "Site") is owned and operated by EIRFROST, Karol
          Ligeza.
          <br />
          <br />
          EIRFROST is the data controller and can be contacted at: <br />
          <br />
          info@eirfrost.ie
          <br />
          +353 89 981 6333
          <br />
          Galway, Ireland
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>Purpose</u>
          </strong>
          <br />
          The purpose of this privacy policy (this "Privacy Policy") is to
          inform users of our Site of the following:
        </p>
        <ol start="1">
          <li value="1">
            <span>The personal data we will collect;</span>
            <br />
          </li>
          <li value="2">
            <span>Use of collected data;</span>
            <br />
          </li>
          <li value="3">
            <span>Who has access to the data collected;</span>
            <br />
          </li>
          <li value="4">
            <span>The rights of Site users; and</span>
            <br />
          </li>
          <li value="5">
            <span>The Site's cookie policy.</span>
            <br />
          </li>
        </ol>
        <p style={{ textAlign: "left" }}>
          This Privacy Policy applies in addition to the terms and conditions of
          our Site.
        </p>
        <div>
          <p style={{ textAlign: "left" }}>
            <strong>
              <u>GDPR</u>
            </strong>
            <br />
            For users in the European Union, we adhere to the Regulation (EU)
            2016/679 of the European Parliament and of the Council of 27 April
            2016, known as the General Data Protection Regulation (the "GDPR").
            For users in the United Kingdom, we adhere to the GDPR as enshrined
            in the Data Protection Act 2018.
            <br />
            <br />
            We have not appointed a Data Protection Officer as we do not fall
            within the categories of controllers and processors required to
            appoint a Data Protection Officer under Article 37 of the GDPR.
          </p>
        </div>
        <div>
          <p style={{ textAlign: "left" }}>
            <strong>
              <u>Consent</u>
            </strong>
            <br />
            By using our Site users agree that they consent to:
          </p>
          <ol start="1">
            <li value="1">
              <span>The conditions set out in this Privacy Policy.</span>
              <br />
            </li>
          </ol>
        </div>
        <div>
          <p style={{ textAlign: "left" }}>
            <strong>
              <u>Legal Basis for Processing</u>
            </strong>
            <br />
            We collect and process personal data about users in the EU only when
            we have a legal basis for doing so under Article 6 of the GDPR.{" "}
            <br />
            <br />
            We rely on the following legal basis to collect and process the
            personal data of users in the EU:
          </p>
          <ol start="1">
            <li value="1">
              <span>
                Processing of user personal data is necessary for us to take, at
                the request of a user, steps before entering a contract or for
                the performance of a contract to which a user is a party. If a
                user does not provide the the personal data necessary to perform
                a contract the consequences are as follows: Eirfrost will not be
                able to contact the customer..
              </span>
              <br />
            </li>
          </ol>
        </div>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>How We Use Personal Data</u>
          </strong>
          <br />
          Data collected on our Site will only be used for the purposes
          specified in this Privacy Policy or indicated on the relevant pages of
          our Site. We will not use your data beyond what we disclose in this
          Privacy Policy.
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>Who We Share Personal Data With</u>
          </strong>
          <br />
          <u>Employees</u>
          <br />
          We may disclose user data to any member of our organisation who
          reasonably needs access to user data to achieve the purposes set out
          in this Privacy Policy.
        </p>
        <div>
          <p style={{ textAlign: "left" }}>
            <u>Other Disclosures</u>
            <br />
            We will not sell or share your data with other third parties, except
            in the following cases:
          </p>
          <ol start="1">
            <li value="1">
              <span>If the law requires it;</span>
              <br />
            </li>
            <li value="2">
              <span>If it is required for any legal proceeding;</span>
              <br />
            </li>
            <li value="3">
              <span>To prove or protect our legal rights; and</span>
              <br />
            </li>
            <li value="4">
              <span>
                To buyers or potential buyers of this company in the event that
                we seek to sell the company.
              </span>
              <br />
            </li>
          </ol>
          <p style={{ textAlign: "left" }}>
            If you follow hyperlinks from our Site to another Site, please note
            that we are not responsible for and have no control over their
            privacy policies and practices.
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>How Long We Store Personal Data</u>
          </strong>
          <br />
          User data will be stored until the purpose the data was collected for
          has been achieved.
          <br />
          <br />
          You will be notified if your data is kept for longer than this period.
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>How We Protect Your Personal Data</u>
          </strong>
          <br />
          In order to protect your security, we use the strongest available
          browser encryption and store all of our data on servers in secure
          facilities. All data is only accessible to our employees. Our
          employees are bound by strict confidentiality agreements and a breach
          of this agreement would result in the employee's dismissal.
          <br />
          <br />
          While we take all reasonable precautions to ensure that user data is
          secure and that users are protected, there always remains the risk of
          harm. The Internet as a whole can be insecure at times and therefore
          we are unable to guarantee the security of user data beyond what is
          reasonably practical.
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>Your Rights as a User</u>
          </strong>
          <br />
          Under the GDPR, you have the following rights:
        </p>
        <ol start="1">
          <li value="1">
            <span>Right to be informed;</span>
            <br />
          </li>
          <li value="2">
            <span>Right of access;</span>
            <br />
          </li>
          <li value="3">
            <span>Right to rectification;</span>
            <br />
          </li>
          <li value="4">
            <span>Right to erasure;</span>
            <br />
          </li>
          <li value="5">
            <span>Right to restrict processing;</span>
            <br />
          </li>
          <li value="6">
            <span>Right to data portability; and</span>
            <br />
          </li>
          <li value="7">
            <span>Right to object.</span>
            <br />
          </li>
        </ol>
        <div>
          <p style={{ textAlign: "left" }}>
            <strong>
              <u>Children</u>
            </strong>
            <br />
            We do not knowingly collect or use personal data from children under
            16 years of age. If we learn that we have collected personal data
            from a child under 16 years of age, the personal data will be
            deleted as soon as possible. If a child under 16 years of age has
            provided us with personal data their parent or guardian may contact
            our privacy officer.
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>
              How to Access, Modify, Delete, or Challenge the Data Collected
            </u>
          </strong>
          <br />
          If you would like to know if we have collected your personal data, how
          we have used your personal data, if we have disclosed your personal
          data and to who we disclosed your personal data, if you would like
          your data to be deleted or modified in any way, or if you would like
          to exercise any of your other rights under the GDPR, please contact
          our privacy officer here:
          <br />
          <br />
          Karol Damian Ligeza
          <br />
          info@eirfrost.ie
          <br />
          +353 89 981 6333
          <br />
          Galway, Ireland
        </p>
        <div>
          <p style={{ textAlign: "left" }}>
            <strong>
              <u>Cookie Policy</u>
            </strong>
            <br />A cookie is a small file, stored on a user's hard drive by a
            website. Its purpose is to collect data relating to the user's
            browsing habits. You can choose to be notified each time a cookie is
            transmitted. You can also choose to disable cookies entirely in your
            internet browser, but this may decrease the quality of your user
            experience.
          </p>
          <p style={{ textAlign: "left" }}>
            We use the following types of cookies on our Site:
          </p>
          <ol start="1">
            <li value="1">
              <u>
                <span>Functional cookies</span>
              </u>
              <br />
              Functional cookies are used to remember the selections you make on
              our Site so that your selections are saved for your next visits.
              <br />
            </li>
          </ol>
        </div>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>Modifications</u>
          </strong>
          <br />
          This Privacy Policy may be amended from time to time in order to
          maintain compliance with the law and to reflect any changes to our
          data collection process. When we amend this Privacy Policy we will
          update the "Effective Date" at the top of this Privacy Policy. We
          recommend that our users periodically review our Privacy Policy to
          ensure that they are notified of any updates. If necessary, we may
          notify users by email of changes to this Privacy Policy.
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>Complaints</u>
          </strong>
          <br />
          If you have any complaints about how we process your personal data,
          please contact us through the contact methods listed in the{" "}
          <em>Contact Information</em> section so that we can, where possible,
          resolve the issue. If you feel we have not addressed your concern in a
          satisfactory manner you may contact a supervisory authority. You also
          have the right to directly make a complaint to a supervisory
          authority. You can lodge a complaint with a supervisory authority by
          contacting the Data Protection Commission.
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>
            <u>Contact Information</u>
          </strong>
          <br />
          If you have any questions, concerns or complaints, you can contact our
          privacy officer, Karol Damian Ligeza, at:
          <br />
          <br />
          info@eirfrost.ie
          <br />
          +353 89 981 6333
          <br />
          Galway, Ireland
        </p>
      </div>
    </div>
  );
}

export default Privacy;
