import { ContactUs } from "../Components";
import { config } from "../config";
import { Helmet } from "react-helmet-async";

function Service() {
  return (
    <div className="page">
      <Helmet>
        <link rel="canonical" href={config.baseUrl + config.servicePath} />
      </Helmet>
      {/* <header className="page-section">
        <div className="page-left-section">
          <h1>Service for customers</h1>
        </div>
      </header> */}
      <ContactUs useServiceForm={true} />
    </div>
  );
}

export default Service;
