import logo from "../Public/logo.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { HashLink as Link } from "react-router-hash-link";
import { useState } from "react";
import { TelephoneFill } from "react-bootstrap-icons";
import "./NavigationBar.css"

function NavigationBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = (force) => {
    if (force || menuOpen) {
      setMenuOpen(!menuOpen);
      return;
    }
  };

  const handleClose = () => setMenuOpen(false);

  return (
    <Navbar expand="lg" className="bg-body-tertiary fixed-top navbar-body">
      <Container>
        <Link to="/#top">
          <Navbar.Brand>
            <Image
              src={logo}
              width="170"
              height="30"
              className="d-inline-block align-top"
              alt="Eirfrost logo"
            />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle
          onClick={() => toggleMenu(true)}
        >

        </Navbar.Toggle>
        <Navbar.Offcanvas
          className="justify-content-center"
          placement="end"
          restoreFocus={false}
          show={menuOpen}
          onHide={handleClose}
          scroll
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <Link to="/#top">
                <Image
                  src={logo}
                  width="170"
                  height="30"
                  className="d-inline-block align-top"
                  alt="Eirfrost logo"
                  onClick={() => toggleMenu(false)}
                />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="ms-auto me-auto">
              <Link
                to="/#benefits-co2"
                className="nav-link navbar-link"
                onClick={() => toggleMenu(false)}
              >
                CO2 refrigeration systems
              </Link>
              <Link
                to="/#about-us"
                className="nav-link navbar-link"
                onClick={() => toggleMenu(false)}
              >
                About us
              </Link>
              <Link
                to="/#our-work"
                className="nav-link navbar-link"
                onClick={() => toggleMenu(false)}
              >
                Our work
              </Link>
              <Link
                to="/#contact"
                className="nav-link navbar-link"
                onClick={() => toggleMenu(false)}
              >
                Contact us
              </Link>
            </Nav>
            <Nav>
              <a className="nav-link ms-auto me-auto" href="tel:+353899816333">
                <Button variant="primary" className="navbar-button">
                  <TelephoneFill className="phone-icon"/>+353 89 981 6333
                </Button>
              </a>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
