import "./GalleryPictureList.css";

import gallery1 from "../Public/Gallery/our_work_1.webp";
import gallery2 from "../Public/Gallery/our_work_2.webp";
import gallery3 from "../Public/Gallery/our_work_3.webp";
import gallery5 from "../Public/Gallery/our_work_5.webp";
import gallery6 from "../Public/Gallery/our_work_6.webp";
import gallery8 from "../Public/Gallery/our_work_8.webp";
import gallery9 from "../Public/Gallery/our_work_9.webp";
import gallery10 from "../Public/Gallery/our_work_10.webp";
import gallery11 from "../Public/Gallery/our_work_11.webp";
import gallery13 from "../Public/Gallery/our_work_13.webp";
import gallery14 from "../Public/Gallery/our_work_14.webp";
import gallery15 from "../Public/Gallery/our_work_15.webp";
import gallery16 from "../Public/Gallery/our_work_16.webp";
import gallery17 from "../Public/Gallery/our_work_17.webp";
import gallery18 from "../Public/Gallery/our_work_18.webp";
import gallery19 from "../Public/Gallery/our_work_19.webp";
import gallery20 from "../Public/Gallery/our_work_20.webp";
import gallery21 from "../Public/Gallery/our_work_21.webp";
import gallery23 from "../Public/Gallery/our_work_23.webp";
import gallery24 from "../Public/Gallery/our_work_24.webp";
import gallery25 from "../Public/Gallery/our_work_25.webp";
import gallery26 from "../Public/Gallery/our_work_26.webp";
import gallery28 from "../Public/Gallery/our_work_28.webp";
import gallery29 from "../Public/Gallery/our_work_29.webp";
import gallery30 from "../Public/Gallery/our_work_30.webp";
import gallery31 from "../Public/Gallery/our_work_31.webp";

import gallery1_thumbnail from "../Public/Gallery/Thumbnails/our_work_1_thumbnail.webp";
import gallery2_thumbnail from "../Public/Gallery/Thumbnails/our_work_2_thumbnail.webp";
import gallery3_thumbnail from "../Public/Gallery/Thumbnails/our_work_3_thumbnail.webp";
import gallery5_thumbnail from "../Public/Gallery/Thumbnails/our_work_5_thumbnail.webp";
import gallery6_thumbnail from "../Public/Gallery/Thumbnails/our_work_6_thumbnail.webp";
import gallery8_thumbnail from "../Public/Gallery/Thumbnails/our_work_8_thumbnail.webp";
import gallery9_thumbnail from "../Public/Gallery/Thumbnails/our_work_9_thumbnail.webp";
import gallery10_thumbnail from "../Public/Gallery/Thumbnails/our_work_10_thumbnail.webp";
import gallery11_thumbnail from "../Public/Gallery/Thumbnails/our_work_11_thumbnail.webp";
import gallery13_thumbnail from "../Public/Gallery/Thumbnails/our_work_13_thumbnail.webp";
import gallery14_thumbnail from "../Public/Gallery/Thumbnails/our_work_14_thumbnail.webp";
import gallery15_thumbnail from "../Public/Gallery/Thumbnails/our_work_15_thumbnail.webp";
import gallery16_thumbnail from "../Public/Gallery/Thumbnails/our_work_16_thumbnail.webp";
import gallery17_thumbnail from "../Public/Gallery/Thumbnails/our_work_17_thumbnail.webp";
import gallery18_thumbnail from "../Public/Gallery/Thumbnails/our_work_18_thumbnail.webp";
import gallery19_thumbnail from "../Public/Gallery/Thumbnails/our_work_19_thumbnail.webp";
import gallery20_thumbnail from "../Public/Gallery/Thumbnails/our_work_20_thumbnail.webp";
import gallery21_thumbnail from "../Public/Gallery/Thumbnails/our_work_21_thumbnail.webp";
import gallery23_thumbnail from "../Public/Gallery/Thumbnails/our_work_23_thumbnail.webp";
import gallery24_thumbnail from "../Public/Gallery/Thumbnails/our_work_24_thumbnail.webp";
import gallery25_thumbnail from "../Public/Gallery/Thumbnails/our_work_25_thumbnail.webp";
import gallery26_thumbnail from "../Public/Gallery/Thumbnails/our_work_26_thumbnail.webp";
import gallery28_thumbnail from "../Public/Gallery/Thumbnails/our_work_28_thumbnail.webp";
import gallery29_thumbnail from "../Public/Gallery/Thumbnails/our_work_29_thumbnail.webp";
import gallery30_thumbnail from "../Public/Gallery/Thumbnails/our_work_30_thumbnail.webp";
import gallery31_thumbnail from "../Public/Gallery/Thumbnails/our_work_31_thumbnail.webp";

import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useImagePreloader } from "./Hooks";

function GalleryPicture({ image, alt, openLightbox }) {
  const { isPreloaded } = useImagePreloader([image]);
  return (
    <div className="gallery-picture-container">
      {isPreloaded ? (
        <img
          className="gallery-picture"
          src={image}
          alt={alt}
          onClick={() => openLightbox()}
        />
      ) : (
        <Skeleton className="gallery-picture-skeleton" />
      )}
    </div>
  );
}

function GalleryPictureList() {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(-1);

  const photos = [
    {
      src: gallery1,
      description: "Compressor room for 4 cold rooms and 2 freezers",
      thumbnailSrc: gallery1_thumbnail,
      width: 1500,
      height: 2000,
    },
    {
      src: gallery2,
      description: "Installation of condensing units in progress",
      thumbnailSrc: gallery2_thumbnail,
      width: 1080,
      height: 1920,
    },
    {
      src: gallery3,
      description: "CO2 units ready to start",
      thumbnailSrc: gallery3_thumbnail,
      width: 1920,
      height: 1080,
    },
    {
      src: gallery5,
      description: "3 units of R744 right after startup",
      thumbnailSrc: gallery5_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery6,
      description: "Water installation for cooling the condenser",
      thumbnailSrc: gallery6_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery8,
      description: "Two systems installed in the basement for cold and freezing room",
      thumbnailSrc: gallery8_thumbnail,
      width: 1500,
      height: 2000,
    },
    {
      src: gallery10,
      description: "CO2 units fit in small places",
      thumbnailSrc: gallery10_thumbnail,
      width: 1920,
      height: 1080,
    },
    {
      src: gallery9,
      description: "CO2 systems used to cool the server room",
      thumbnailSrc: gallery9_thumbnail,
      width: 1920,
      height: 1080,
    },
    {
      src: gallery11,
      description: "Unit with co2 compressor mounted to a steel structure",
      thumbnailSrc: gallery11_thumbnail,
      width: 1920,
      height: 1080,
    },
    {
      src: gallery13,
      description: "One unit is enough for cold room and freezer",
      thumbnailSrc: gallery13_thumbnail,
      width: 1920,
      height: 1080,
    },
    {
      src: gallery31,
      description: "Cooler and freezer in the restaurant",
      thumbnailSrc: gallery31_thumbnail,
      width: 1080,
      height: 1920,
    },
    {
      src: gallery14,
      description: "The unit mounted on the floor does not transmit vibrations",
      thumbnailSrc: gallery14_thumbnail,
      width: 1500,
      height: 2000,
    },
    {
      src: gallery15,
      description: "Dairy refrigerators cooled with carbon dioxide",
      thumbnailSrc: gallery15_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery16,
      description: "Refrigerated display case with vegetables cooled with CO2",
      thumbnailSrc: gallery16_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery17,
      description: "R744 evaporator with EEV (electronic expension valve)",
      thumbnailSrc: gallery17_thumbnail,
      width: 1920,
      height: 1080,
    },
    {
      src: gallery18,
      description: "tTwo CO2 systems in a fish processing plant",
      thumbnailSrc: gallery18_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery19,
      description: "CO2 condensing unit with evaporator",
      thumbnailSrc: gallery19_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery20,
      description: "CO2 system for cooling the production room",
      thumbnailSrc: gallery20_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery21,
      description: "Refrigeration unit with evaporator mounted on a steel structure",
      thumbnailSrc: gallery21_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery23,
      description: "CO2-cooled freezer ready to start",
      thumbnailSrc: gallery23_thumbnail,
      width: 1500,
      height: 2000,
    },
    {
      src: gallery24,
      description: "R744 evaporator in the cold room",
      thumbnailSrc: gallery24_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery25,
      description: "Cold store with two independent co2 cooling systems",
      thumbnailSrc: gallery25_thumbnail,
      width: 1080,
      height: 1920,
    },
    {
      src: gallery26,
      description: "Brand new freezing room and cold room",
      thumbnailSrc: gallery26_thumbnail,
      width: 2000,
      height: 1500,
    },
    {
      src: gallery28,
      description: "CO2 compressor rack for superstore",
      thumbnailSrc: gallery28_thumbnail,
      width: 1500,
      height: 2000,
    },
    {
      src: gallery29,
      description: "CO2 installation in the cold store",
      thumbnailSrc: gallery29_thumbnail,
      width: 1080,
      height: 1920,
    },
    {
      src: gallery30,
      description: "Evaporators in a large warehouse cooled with CO2",
      thumbnailSrc: gallery30_thumbnail,
      width: 1080,
      height: 1920,
    }
  ];

  return (
    <div className="gallery-picture-list page-section">
      {photos.map((photo, index) => (
        <GalleryPicture
          key={index}
          image={photo.thumbnailSrc}
          alt={photo.description}
          openLightbox={() => {
            setCurrentPhotoIndex(index);
          }}
        />
      ))}
      <Lightbox
        index={currentPhotoIndex}
        slides={photos}
        open={currentPhotoIndex >= 0}
        close={() => setCurrentPhotoIndex(-1)}
        plugins={[Zoom, Fullscreen, Captions]}
      />
    </div>
  );
}

export default GalleryPictureList;
