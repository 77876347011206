import "./Co2.css";
import co2Image from "../Public/LandingPage/benefits.webp";
import { useImagePreloader } from "./Hooks";
import Skeleton from "react-loading-skeleton";

function Co2() {
  const { isPreloaded } = useImagePreloader([co2Image]);

  return (
    <div id="benefits-co2" className="page-section">
      <div className="image-container-left page-left-section">
        <div className="image-wire-left">
          {isPreloaded ? (
            <img
              className="eirfrost-image"
              src={co2Image}
              alt="Refregerators"
            />
          ) : (
            <Skeleton className="eirfrost-image-skeleton" />
          )}
        </div>
      </div>
      <div className="page-right-section">
        <h2>CO2 refrigeration systems (R744)</h2>
        <p className="co2-text">
          CO2 refrigeration systems have gained popularity in modern industry,
          mainly due to their numerous advantages. The most prominent benefits
          are undoubtedly their positive impact on the environment and operating
          cost reduction. By replacing CFC and HFC refrigerants with carbon
          dioxide, these systems offer a fully ecological solution, eliminating
          the harmful effects of traditional refrigerants on the ozone layer.
          <br />
          Moreover, R744 installations not only ensure higher efficiency compared
          to freon and synthetic refrigerants, but also boast smaller sizes of
          compressors and the installation itself. Those systems offer excellent
          performance at evaporating temperatures below -35°C and reduced
          sensitivity to pressure drops. They are also cost-effective, thanks to
          CO2 being a potential waste product in various technological
          processes.
        </p>
      </div>
    </div>
  );
}

export default Co2;
