import "./ContactUs.css";
import contactUsImage from "../Public/LandingPage/contact.webp";
import { EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";
import ContactForm from "./ContactForm";
import ServiceContactForm from "./ServiceContactForm";
import { useImagePreloader } from "./Hooks";
import Skeleton from "react-loading-skeleton";

function ContactUs({ useServiceForm }) {
  const { isPreloaded } = useImagePreloader([contactUsImage]);

  return (
    <div id="contact" className="page-section contact-us-section">
      <div className="page-left-section contact-us-left">
        <div className="image-container-left contact-us-image">
          <div className="image-wire-left">
            {isPreloaded ? (
              <img
                className="eirfrost-image"
                src={contactUsImage}
                alt="Contact us"
              />
            ) : (
              <Skeleton className="eirfrost-image-skeleton" />
            )}
          </div>
        </div>
        <div className="contact-us-details">
          {useServiceForm && <h2>Service for customers</h2>}
          {!useServiceForm && <h2>Contact us</h2>}
          <p className="contact-us-text">
            If you have any questions, please contact us.
            <br />
            Write us a message or call us on the phone number below.
          </p>
          <div className="contact-info">
            <div className="contact-info-section">
              <div className="contact-info-link">
                <a className="contact-info-link" href="mailto:info@eirfrost.ie">
                  <EnvelopeFill className="contact-info-icon" />
                  info@eirfrost.ie
                </a>
              </div>
              <div className="contact-info-link">
                <a className="contact-info-link" href="tel:+353899816333">
                  <TelephoneFill className="contact-info-icon" />
                  +353 89 981 6333
                </a>
              </div>
            </div>

            <div className="contact-info-section">
              <div className="contact-info-strong">Eirfrost</div>
              Karol Damian Ligeza
              <br />
              CRN: 719024
              <br />
              Galway, Ireland
            </div>
          </div>
        </div>
      </div>
      {useServiceForm ? <ServiceContactForm /> : <ContactForm />}

    </div>
  );
}

export default ContactUs;
