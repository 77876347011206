import { useEffect } from "react";
import { AboutUs, BenefitsCo2, Co2, ContactUs, Hero } from "../Components";
import OurWork from "../Components/OurWork";
import { config } from "../config";
import { Helmet } from "react-helmet-async";

function LandingPage() {
  useEffect(() => {
    setTimeout(() => {
      const allowedAnchors = [
        "home",
        "benefits-co2",
        "about-us",
        "our-work",
        "contact",
      ];
      allowedAnchors.forEach((element) => {
        if (window.location.hash.includes(element)) {
          const availabilitySectionNode = document.getElementById(element);
          if (availabilitySectionNode) {
            availabilitySectionNode.scrollIntoView({ behavior: "smooth" });
          }
        }
      });
    }, 100);
  }, []);

  return (
    <>
      <div className="page">
        <Helmet>
          <link rel="canonical" href={config.baseUrl} />
        </Helmet>

        <Hero />
        <Co2 />
        <BenefitsCo2 />
        <AboutUs />
        <OurWork />
        <ContactUs />
      </div>
    </>
  );
}

export default LandingPage;
