import { useEffect, useState } from "react";
import "./CookiesBanner.css";

import { HashLink as Link } from "react-router-hash-link";
import { Button } from "react-bootstrap";

import cookieImage from "../Public/cookies.svg";
import closeImage from "../Public/close.svg";
import Cookies from "js-cookie";

function CookiesBanner() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('eirfrost-cookies');
    if(consent !== "yes") {
      setIsOpen(true);
    }
  }, []);

  const closeBanner = () => {
    Cookies.set("eirfrost-cookies", "yes", { expires: 180 });
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="cookies-banner outline-section">
          <div className="outline-section-container cookies-banner-container">
            <div className="cookies-header">
              <img
                src={closeImage}
                width={20}
                height={20}
                alt="Close"
                className="cookie-close"
                onClick={closeBanner}
              />
            </div>
            <img
              src={cookieImage}
              width={50}
              height={50}
              alt="Cookie"
              className="cookie-image"
            />
            <p className="cookies-banner-text">
              Our website uses only essential cookies. These cookies are
              necessary for the website to function correctly. For more
              information, please see our{" "}
              <Link to="/privacy/#top" className="cookies-link">
                Privacy policy
              </Link>
            </p>
            <Button
              variant="primary"
              className="eirfrost-button cookies-button"
              onClick={() => closeBanner()}
            >
              Accept
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default CookiesBanner;
