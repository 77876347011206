import { Outlet } from "react-router-dom";
import { NavigationBar, Footer, CookiesBanner } from "./Components";
import { SkeletonTheme } from "react-loading-skeleton";

function Layout() {
  return (
    <>
      <SkeletonTheme baseColor="#cbf0f5" highlightColor="#edfdff">
        <NavigationBar />
        <Outlet />
        <Footer />
        <CookiesBanner />
      </SkeletonTheme>
    </>
  );
}

export default Layout;
