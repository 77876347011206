export const config = {
  baseUrl: "https://eirfrost.ie/",
  outWorkPath: 'our-work/',
  servicePath: 'service/',
  privacyPath: 'privacy/',
  contactFormUrl:
    "https://prod-30.northeurope.logic.azure.com/workflows/1df204e9232a44f0a4f819587fd9acac/triggers/request/paths/invoke/contact?api-version=2016-10-01&sp=%2Ftriggers%2Frequest%2Frun&sv=1.0&sig=WgrDXIgao9MxZo6kp1tskMuL97tqO5TpArAGHH6j7PA",
  serviceFormUrl:
    "https://prod-16.northeurope.logic.azure.com/workflows/ec01565b3fd741858018298408d18103/triggers/request/paths/invoke/contact?api-version=2016-10-01&sp=%2Ftriggers%2Frequest%2Frun&sv=1.0&sig=T9svBt0o2XZ2Meei9Bz-dQSFjonaQAsg9Vm8HE_HHoI",
};
