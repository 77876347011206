import "./BenefitsCo2.css";
import gwpImage from "../Public/Benefits/gwp-odp.svg";
import leakageImage from "../Public/Benefits/leakages.svg";
import costsImage from "../Public/Benefits/costs.svg";
import efficencyImage from "../Public/Benefits/efficiency.svg";
import chargeImage from "../Public/Benefits/regrigerant-charge.svg";

function Benefit({ benefitImage, title, description }) {
  return (
    <div className="benefit">
      <img src={benefitImage} alt={title + " graphic"} className="benefit-icon" />
      <div className="benefit-title">{title}</div>
      <div className="benefit-description">{description}</div>
    </div>
  );
}

function BenefitsCo2() {
  return (
    <div id="benefits-co2-list" className="page-section outline-section">
      <div className="outline-section-container">
        <h2>
          Benefits of CO2
        </h2>
        <div className="benefits-container">
          <Benefit
            benefitImage={gwpImage}
            title="GWP-1 ODP-0"
            description="Environment friendly"
          />
          <Benefit
            benefitImage={leakageImage}
            title="-96%"
            description="Risk of leakages"
          />
          <Benefit
            benefitImage={costsImage}
            title="-25%"
            description="Reduction of capital and operating cost"
          />
          <Benefit
            benefitImage={chargeImage}
            title="-80%"
            description="Reduction in refrigerant charge"
          />
          <Benefit
            benefitImage={efficencyImage}
            title="100%"
            description="Efficiency by variable-speed compressors and electronic expension valves"
          />
        </div>
      </div>
    </div>
  );
}

export default BenefitsCo2;
