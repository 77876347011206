import "./OurWork.css";
import gallery1 from "../Public/Gallery/Thumbnails/our_work_1_thumbnail.webp";
import gallery2 from "../Public/Gallery/Thumbnails/our_work_16_thumbnail.webp";
import gallery3 from "../Public/Gallery/Thumbnails/our_work_19_thumbnail.webp";
import gallery4 from "../Public/Gallery/Thumbnails/our_work_26_thumbnail.webp";
import { HashLink as Link } from "react-router-hash-link";
import { Button } from "react-bootstrap";

function Picture({ image, alt }) {
  return (
    <div className="picture">
      <img className="picture-thumbnail" src={image} alt={alt} />
    </div>
  );
}

function OurWork() {
  return (
    <div id="our-work" className="page-section outline-section">
      <div className="outline-section-container our-work-section-container">
        <div className="our-work-padding page-left-section">
          <h2 className="our-work-header">Our work</h2>
          <p className="our-work-text">
            At Eirfrost, we take immense pride in each project's successful
            completion, ensuring that every system reflects our commitment to
            quality and innovation. Explore the gallery to witness firsthand the
            superior craftsmanship and attention to detail that sets our work
            apart in the refrigeration industry.
          </p>
          <Link to="/our-work/#top" className="display-only-large">
            <Button variant="primary" className="eirfrost-button">
              See our work
            </Button>
          </Link>
        </div>

        <div className="our-work-examples page-right-section">
          <Picture image={gallery1} alt="Work example 1" />
          <Picture image={gallery2} alt="Work example 2" />
          <Picture image={gallery3} alt="Work example 3" />
          <Picture image={gallery4} alt="Work example 4" />
        </div>
        <div className="our-work-padding display-only-small">
          <Link to="/our-work/#top">
            <Button variant="primary" className="eirfrost-button">
              See our work
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OurWork;
