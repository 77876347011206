import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "../Public/logo.svg";
import { HashLink as Link } from "react-router-hash-link";
import { EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";
import "./Footer.css";

function Footer() {
  const getCurrentYear = () => new Date().getFullYear();

  return (
    <footer>
      <Container>
        <Row>
          <Col xs="12" lg="4" className="footer-section">
            <Link to="/#top" className="footer-element">
              <Image src={logo} alt="Eirfrost logo" className="footer-logo" />
            </Link>
          </Col>
          <Col xs="12" lg="3" className="footer-section">
            <ul className="nav footer-nav">
              <li className="nav-item">
                <Link
                  to="/#benefits-co2"
                  className="nav-link footer-link footer-nav-link"
                >
                  CO2 refrigeration systems
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/#about-us"
                  className="nav-link footer-link footer-nav-link"
                >
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/#our-work"
                  className="nav-link footer-link footer-nav-link"
                >
                  Our work
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/#contact"
                  className="nav-link footer-link footer-nav-link"
                >
                  Contact us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/privacy/#top"
                  className="nav-link footer-link footer-nav-link"
                >
                  Privacy policy
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs="12" lg="3" className="footer-section">
            <div className="footer-strong">
              <strong>Contact info</strong>
            </div>
            <div>
              <div className="footer-contact-text">
                <a
                  className="footer-contact-link"
                  href="mailto:info@eirfrost.ie"
                >
                  <EnvelopeFill className="footer-contact-icon" />
                  info@eirfrost.ie
                </a>
              </div>
              <div className="footer-contact-text">
                <a className="footer-contact-link" href="tel:+353899816333">
                  <TelephoneFill className="footer-contact-icon" />
                  +353 89 981 6333
                </a>
              </div>
            </div>
          </Col>
          <Col xs="12" lg="2" className="footer-section">
            <div className="footer-strong">
              <strong>Eirfrost</strong>
            </div>
            <div>
              <div className="footer-text">Karol Damian Ligeza</div>
              <div className="footer-text">CRN: 719024</div>
              <div className="footer-text">Galway, Ireland</div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="text-center text-muted">
            © {getCurrentYear()}, Eirfrost
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
