import { Button } from "react-bootstrap";
import "./Hero.css";
import heroImage from "../Public/LandingPage/hero.webp";
import { HashLink as Link } from "react-router-hash-link";
import Skeleton from "react-loading-skeleton";
import { useImagePreloader } from "./Hooks";

function Hero() {
  const { isPreloaded } = useImagePreloader([heroImage]);

  return (
    <header id="home" className="page-section">
      <div className="image-container page-right-section">
        <div className="image-wire-right">
          {isPreloaded ? (
            <img
              className="eirfrost-image"
              src={heroImage}
              alt="Refregerators graphic"
            />
          ) : (
            <Skeleton className="eirfrost-image-skeleton" />
          )}
        </div>
      </div>
      <div className="page-left-section">
        <h1>
          EIRFROST: design, construction, modernization of refrigeration systems
        </h1>
        <p className="hero-text">
          Comprehensive repairs and service of refrigeration equipment: cold
          stores, freezers, chillers, freecooling, heat pumps, technological
          equipment, etc.
        </p>
        <Link to="/#contact">
          <Button variant="primary" className="eirfrost-button">
            Contact us
          </Button>
        </Link>
      </div>
    </header>
  );
}

export default Hero;
