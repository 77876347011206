import "./AboutUs.css";
import aboutUsImage from "../Public/LandingPage/about-us.webp";
import { useImagePreloader } from "./Hooks";
import Skeleton from "react-loading-skeleton";

function AboutUs() {
  const { isPreloaded } = useImagePreloader([aboutUsImage]);

  return (
    <div id="about-us" className="page-section">
      <div className="image-container-left page-left-section">
        <div className="image-wire-left">
          {isPreloaded ? (
            <img className="eirfrost-image" src={aboutUsImage} alt="About us" />
          ) : (
            <Skeleton className="eirfrost-image-skeleton" />
          )}
        </div>
      </div>
      <div className="page-right-section">
        <h2>About us</h2>
        <p className="about-us-text">
          Our core expertise lies in the assembly, disassembly, and
          modernization of various refrigeration equipment, including cold
          rooms, freezing chambers, refrigeration systems, heat pumps, heat
          recovery, and industrial refrigeration equipment. Additionally, we
          excel in refrigeration automation, handling both commercial and
          industrial installations from design to implementation. Our services
          encompass warranty and post-warranty support, ensuring that we deliver
          reliable solutions.
        </p>
        <p className="about-us-text about-us-text-no-margin">
          We are at the forefront of technological advancements, seamlessly
          integrating new innovations into practical applications. Fearlessly
          accepting our clients' challenges, since 2017, we have been fully
          committed to assembling cutting-edge CO2-based devices and
          installations. Our skilled and continually expanding team possesses
          the necessary qualifications to execute every project with reliability
          and diligence, prioritizing your satisfaction and earning your
          positive recommendations.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
