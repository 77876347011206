import { Alert, Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import "./ContactUs.css";
import { useState } from "react";
import { config } from "../config";

function ContactForm() {
  const [isLoading, setIsloading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [contactFormData, setContactFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    phone: "",
    eircode: "",
    message: "",
  });

  const handleChangeFormData = (event) => {
    setContactFormData({
      ...contactFormData,
      [event.target.name]: event.target.value,
    });
  };

  const sendForm = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsloading(true);
    await fetch(config.contactFormUrl, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(contactFormData),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        setIsFormSent(true);
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(
          "Please try again or contact us directly using our phone number or email address."
        );
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const clearForm = () => {
    setContactFormData({
      name: "",
      companyName: "",
      email: "",
      phone: "",
      eircode: "",
      message: "",
    });
    setIsFormSent(false);
    setErrorMessage("");
  };

  return (
    <Form className="outline-section page-right-section" onSubmit={sendForm}>
      <div className="outline-section-container contact-form">
        {isFormSent && (
          <>
            <h2 className="contact-form-header">Thank you!</h2>
            <p>
              Your message has been sent.
              <br />
              We will reply as soon as possible.
            </p>
            <Button
              variant="primary"
              className="eirfrost-button contact-from-button contact-form-button-resend"
              onClick={() => clearForm()}
            >
              Send another message
            </Button>
          </>
        )}
        {!isFormSent && (
          <>
            <h2 className="contact-form-header">Let us help you!</h2>

            <FloatingLabel
              controlId="nameInput"
              label="Name*"
              className="contact-form-input"
            >
              <Form.Control
                type="text"
                placeholder="Name*"
                name="name"
                value={contactFormData.name}
                onChange={handleChangeFormData}
                required
                minLength={1}
                maxLength={100}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="companyNameInput"
              label="Company name"
              className="contact-form-input"
            >
              <Form.Control
                type="text"
                placeholder="Company name"
                name="companyName"
                value={contactFormData.companyName}
                onChange={handleChangeFormData}
                maxLength={100}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="emailInput"
              label="Email*"
              className="contact-form-input"
            >
              <Form.Control
                type="email"
                placeholder="Email*"
                name="email"
                value={contactFormData.email}
                onChange={handleChangeFormData}
                required
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="phoneInput"
              label="Phone"
              className="contact-form-input"
            >
              <Form.Control
                type="text"
                placeholder="Phone"
                name="phone"
                value={contactFormData.phone}
                onChange={handleChangeFormData}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="eircodeInput"
              label="Eircode*"
              className="contact-form-input"
            >
              <Form.Control
                type="text"
                placeholder="Eircode*"
                name="eircode"
                value={contactFormData.eircode}
                onChange={handleChangeFormData}
                maxLength={100}
                required
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="messageTextarea"
              label="Message*"
              className="contact-form-textarea"
            >
              <Form.Control
                as="textarea"
                type=""
                placeholder="Message*"
                name="message"
                className="contact-form-message"
                value={contactFormData.message}
                onChange={handleChangeFormData}
                required
                maxLength={1000}
              />
            </FloatingLabel>

            {errorMessage && (
              <Alert
                variant="danger"
                onClose={() => setErrorMessage("")}
                dismissible
              >
                <Alert.Heading>Something went wrong!</Alert.Heading>
                <p>{errorMessage}</p>
              </Alert>
            )}

            <Button
              disabled={isLoading}
              variant="primary"
              className="eirfrost-button contact-from-button"
              type="submit"
            >
              {!isLoading && "Send"}
              {isLoading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </>
        )}
      </div>
    </Form>
  );
}

export default ContactForm;
